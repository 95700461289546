// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {LIME_4, MIDNIGHT_BLUE, SEAFOAM_5, WHITE} from "../../styles/colors";

import GDPRContext from "./GDPRContext";
import {MAX_MOBILE_WIDTH} from "../../styles/sizes";

const GDPRBanner = (): React.Node => {
  const {onEnableTracking, onDisableTracking} = React.useContext(GDPRContext);
  return (
    <div className={css(styles.notice)}>
      <div className={css(styles.content)}>
        <p className={css(styles.copy)}>
          We use cookies to personalize content, provide social media features
          and analyze our traffic.
          <a
            className={css(styles.ctaLink)}
            href="https://www.flexport.com/privacy"
          >
            See Details
          </a>
        </p>
      </div>
      <div className={css(styles.buttonGroup)}>
        <button
          type="button"
          className={css(styles.button)}
          onClick={onEnableTracking}
        >
          Accept
        </button>
        <button
          className={css(styles.button, styles.dismiss)}
          onClick={onDisableTracking}
          type="button"
        >
          +
        </button>
      </div>
    </div>
  );
};

export default GDPRBanner;

const styles = StyleSheet.create({
  notice: {
    position: "fixed",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: MIDNIGHT_BLUE,
    color: WHITE,
    padding: "10px 15px",
    zIndex: "10",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [`@media (max-width: ${MAX_MOBILE_WIDTH})`]: {
      flexDirection: "column",
      padding: "35px 25px 25px 25px",
    },
  },
  content: {
    marginBottom: "0",
    display: "flex",
    alignItems: "center",
    [`@media (max-width: ${MAX_MOBILE_WIDTH})`]: {
      flexDirection: "column",
      alignItems: "flex-start",
      position: "relative",
    },
  },
  copy: {
    margin: "0px 10px 0px 0px",
    [`@media (max-width: ${MAX_MOBILE_WIDTH})`]: {
      margin: "10px 0px",
    },
  },
  ctaLink: {
    marginLeft: "10px",
    color: SEAFOAM_5,
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    [`@media (max-width: ${MAX_MOBILE_WIDTH})`]: {
      alignItems: "space-between",
      width: "100%",
      justifyContent: "space-between",
      paddingTop: "10px",
    },
  },
  button: {
    backgroundColor: LIME_4,
    borderRadius: "5px",
    fontSize: "16px",
    border: "none",
    padding: "5px 20px",
    cursor: "pointer",
    color: MIDNIGHT_BLUE,
    ":first-of-type": {
      marginRight: "25px",
    },
  },
  dismiss: {
    backgroundColor: "transparent",
    fontSize: "24px",
    padding: "0px",
    transform: "rotate(45deg)",
    [`@media (max-width: ${MAX_MOBILE_WIDTH})`]: {
      position: "absolute",
      top: 15,
      right: 25,
    },
  },
});
