// @flow
import * as React from "react";
import {useState, useEffect, useCallback} from "react";
import Cookies from "js-cookie";
import GDPRBanner from "./GDPRBanner";
import TrackingTags from "./TrackingTags";

import GDPRContext from "./GDPRContext";

const ACCEPT_COOKIES = "accept-cookies";
const COOKIE_BANNER_DISMISSED = "cookie-banner-dismissed";
const IS_PROD = process.env.GATSBY_SERVER_ENV === "prod";

type Props = {
  children?: React.Node,
};

const GDPRWrapper = (props: Props): React.Node => {
  const {children} = props;

  const [showGDPRBanner, setShowGDPRBanner] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [isEU, setIsEU] = useState(false);
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(false);
  const [hasDismissedCookieBanner, setHasDismissedCookieBanner] =
    useState(false);

  const getRequestLocationData = useCallback(async () => {
    const baseUrl = window?.location?.origin;
    const netlifyFunctionURL = baseUrl
      ? `${baseUrl}/.netlify/functions`
      : ".netlify/functions";
    const response = await fetch(`${netlifyFunctionURL}/country-code`);
    const data = await response.json();
    setCountryCode(data.countryCode);
    if (!data.isEU) {
      setTrackingEnabled(true);
    } else {
      setIsEU(true);
      if (hasAcceptedCookies) {
        setTrackingEnabled(true);
      } else if (!hasAcceptedCookies && !hasDismissedCookieBanner) {
        setShowGDPRBanner(true);
      }
    }
  }, [hasAcceptedCookies, hasDismissedCookieBanner]);

  useEffect(() => {
    setHasAcceptedCookies(Cookies.get(ACCEPT_COOKIES) === "true");
    setHasDismissedCookieBanner(
      Cookies.get(COOKIE_BANNER_DISMISSED) === "true"
    );
    getRequestLocationData();
  }, [hasAcceptedCookies, hasDismissedCookieBanner, getRequestLocationData]);

  const onEnableTracking = () => {
    Cookies.set(ACCEPT_COOKIES, true);
    setShowGDPRBanner(false);
    setTrackingEnabled(true);
  };

  const onDisableTracking = () => {
    Cookies.set(COOKIE_BANNER_DISMISSED, true, {expires: 21});
    Cookies.set(ACCEPT_COOKIES, false);
    setShowGDPRBanner(false);
    setTrackingEnabled(false);
  };

  const GDPRState = {
    isEU,
    countryCode,
    hasEnabledTracking: trackingEnabled,
    onEnableTracking,
    onDisableTracking,
  };

  return (
    <GDPRContext.Provider value={GDPRState}>
      {children}
      {showGDPRBanner && <GDPRBanner />}
      {IS_PROD && <TrackingTags />}
    </GDPRContext.Provider>
  );
};

export default GDPRWrapper;
