// @flow strict
import * as React from "react";

export type TypeGDPRContext = {|
  isEU: boolean,
  hasEnabledTracking: boolean,
  countryCode: null | string,
  onEnableTracking: () => mixed,
  onDisableTracking: () => mixed,
|};

const GDPRContext: React$Context<TypeGDPRContext> = React.createContext({
  isEU: false,
  hasEnabledTracking: false,
  countryCode: null,
  onEnableTracking: () => {
    throw new Error("onEnableTracking is not yet initialized");
  },
  onDisableTracking: () => {
    throw new Error("onDisableTracking is not yet initialized");
  },
});

export default GDPRContext;
